import $ from 'jquery';
import jQuery from 'jquery';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';

import './scss/app.scss';

window.$ = $;
window.jQuery = jQuery;

$(document).ready(function(){
	console.log('document ready');

	$('.de-geus-search input[type="submit"]').val("");

	//$('label input[type="checkbox"][checked="checked"]').parent('label').addClass('checked');


});